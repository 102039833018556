import { strRepeat } from '@/utils';

export function computeAreas( areas, arr = [], parent, tab = 0, excludeInactive ) {

  if ( ! parent ) {
    areas.sort(( a, b ) => b.subareas.length - a.subareas.length );
  }

  areas.forEach( area => {

    area.parent = parent;
    area.tab = tab;

    if ( excludeInactive && area.status === -1 )
      return;

    if ( ! arr.find( a => a.id === area.id ))
      arr.push( area );

    computeAreas( area.subareas, arr, area, tab + 1 );
  });
  return arr;
}

export function computeValue( item ) {
  if ( ! item ) return '';
  return [ strRepeat('—', item.tab || 0 ), item.name ]
    .filter( Boolean )
    .join(' ');
}

export function computeDisplay( item ) {

  if ( ! item ) return '';

  const list = [ item.name ];
  while ( item.parent ) {
    list.push( item.parent.name );
    item = item.parent;
  }

  return list.join(', ');
}
