<template>
  <v-form ref="form" class="pa-2" @submit.prevent="onSave">
    <v-row>
      <v-col cols="12" sm="8">
        <v-row>
          <v-col cols="12" sm="6">
            <image-field
              v-model="model.pics"
              class="mb-2"
              item-image="url"
              :height="isNew ? 200 : 240"
              clearable
              contain
              multiple
            />
          </v-col>
          <v-col cols="12" sm="6">
            <btn
              v-if="!isNew"
              class="mb-3"
              color="primary"
              icon-right="mdi-eye-outline"
              :to="`/offer?customer=${model.id}`"
              block
            >
              {{ $t("offer.title") }}
            </btn>

            <text-field
              v-model="model.name"
              :label="$t('inputs.name.label')"
              :rules="rules.name"
            />

            <text-field
              v-model="model.mail"
              :label="$t('inputs.email.label')"
              :rules="rules.mail"
            />

            <text-field
              v-model="model.phone"
              :label="$t('inputs.phone.label')"
              :rules="rules.phone"
            />
          </v-col>
        </v-row>

        <textarea-field
          v-model="model.description"
          :label="$t('inputs.description.label')"
          :height="isMobile ? undefined : 460"
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="4">
        <category-field
          v-model="model.categoryId"
          :selected="model.filterItems"
          :label="$t('inputs.idCategory.label')"
          :rules="rules.idCategory"
          @selected="model.filterItems = $event"
          min-height="107"
        />

        <tag-field
          v-model="model.tags"
          :label="$t('inputs.tag.label')"
          api="tag"
          item-text="value"
          item-search="value"
          min-height="107"
          @input="onInput('tags', $event)"
        />

        <area-field v-model="model.area" :rules="rules.area" />

        <autocomplete-field
          v-model="model.address"
          :label="$t('inputs.address.label')"
          :rules="rules.address"
          :items="locations"
          :loading="searching"
          :search-input.sync="search"
          item-text="title"
          item-value="id"
          return-object
          hide-no-data
          clearable
          no-filter
        />

        <google-maps
          ref="maps"
          v-model="model"
          min-height="260"
          latitude-key="latitude"
          longitude-key="longitude"
          :editable="editableMap"
          @update:center="onChangePosition"
        >
          <btn
            class="ma-3"
            :title="$t('btn.edit')"
            :color="editableMap ? 'primary' : 'white'"
            @click="editableMap = !editableMap"
            :dark="false"
            width="40"
            height="40"
            fab
          >
            <v-icon>mdi-pencil</v-icon>
          </btn>
        </google-maps>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import TextareaField from "@/components/form/TextareaField";
import AutocompleteField from "@/components/form/AutocompleteField";
import TextField from "@/components/form/TextField";
import ImageField from "@/components/form/ImageField";
import TagField from "@/components/form/TagField";
import CategoryField from "@/components/inputs/CategoryField";
import AreaField from "@/components/inputs/AreaField";
import GoogleMaps from "@/components/GoogleMaps";
import { deepEqual } from "vuetify/lib/util/helpers";
import { required } from "@/utils/rules";
import { mapState } from "vuex";

export default {
  components: {
    TextareaField,
    CategoryField,
    AutocompleteField,
    ImageField,
    TextField,
    TagField,
    AreaField,
    GoogleMaps,
  },
  props: {
    value: Object,
    save: Function,
    isNew: Boolean,
  },
  data() {
    return {
      model: this.value || {},
      editableMap: false,
      searcheableMap: true,
      searching: false,
      search: "",
      locations: [],
    };
  },
  computed: {
    ...mapState("app", ["isMobile"]),
    rules() {
      return {
        name: [required(this.$t("inputs.name.rules.required"))],
        idCategory: [required(this.$t("inputs.idCategory.rules.required"))],
        area: [required(this.$t("inputs.area.rules.required"))],
        address: [required(this.$t("inputs.address.rules.required"))],
      };
    },
  },
  watch: {
    value: "computeValues",
    search: "onSearchAddress",
    "model.address": "onChangeAddress",
  },
  methods: {
    onSave() {
      this.$emit("input", this.model);
      this.save && this.$nextTick(this.save);
    },
    computeValues(model = {}) {
      this.model = model;
      console.log(JSON.stringify(model));

      this.model.filterItems = (model.filterItems || []).map((f) => {
        if (f.filterItem == undefined) {
          return f.filterItemId == undefined
            ? f.id == undefined
              ? f
              : f.id
            : f.filterItemId;
        } else {
          return f.filterItem.id;
        }
      });
      this.model.tags = (model.tags || []).map((t) => ({
        id: t.tagId == undefined ? t.id : t.tagId,
        value: t.tagName == undefined ? t.value : t.tagName,
      }));

      if (typeof model.address === "string") {
        this.model.address = {
          id: 1,
          title: model.address,
          position: {
            lat: model.latitude,
            lng: model.longitude,
          },
        };
      }

      if (this.model.address) {
        this.locations = [this.model.address];
      }
    },
    onSearchAddress(search) {
      if (!search || this.searching) return;

      if (!this.searcheableMap) {
        this.searcheableMap = true;
        return;
      }

      this.searching = false;
      clearInterval(this._interval);

      this._interval = setTimeout(() => {
        this.searching = true;
        this.$refs.maps
          .find(search)
          .then((results) => {
            this.locations = results.map((res) => ({
              id: res.data.place_id,
              title: res.title,
              position: res.position,
            }));

            if (this.model.address) {
              const _address = this.locations.find(
                (a) => a.title === this.model.address.title
              );
              if (_address) {
                this.model.address.id = _address.id;
              } else {
                this.locations.unshift(this.model.address);
              }
            }
          })
          .catch(() => {
            this.locations = [];
          })
          .finally(() => {
            this.searching = false;
          });
      }, 500);
    },
    onChangePosition(pos) {
      if (this.editableMap) {
        this.searcheableMap = false;
        this.$refs.maps.getAddress(pos).then(([data]) => {
          if (!data) return;

          const address = {
            id: data.place_id,
            title: data.formatted_address,
            position: data.geometry.location.toJSON(),
          };

          this.searcheableMap = false;
          this.locations = [address];
          this.$set(this.model, "address", address);
        });
      }
    },
    onChangeAddress(address, old) {
      if (!address || deepEqual(address, old)) return;

      this.search = "";
      this.model = {
        ...this.model,
        latitude: address.position.lat,
        longitude: address.position.lng,
      };
    },
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    },
    onInput(key, value) {
      this.$set(this.model, key, value);
      this.computeValues(this.model);
    },
  },
  beforeMount() {
    this.computeValues();
  },
};
</script>
