<template>
  <div :class="classes">

    <label v-if="label" class="caption">
      {{ label }}
    </label>

    <v-card
      :min-height="minHeight"
      outlined
    >

      <api-field
        ref="input"
        :value="value"
        :rules="rules"
        @input="$emit('input',$event)"
        api="category"
        item-search="name"
        clearable
        chips
      />

      <list
        v-if="filterItems.length"
        v-model="internalSelection"
        :items="filterItems"
        :style="{ maxHeight: computedMaxHeight }"
        multiple
        dense
      >
        <template v-slot:content="item">
          <d-image
            v-bind="item.avatar.image"
            color="transparent"
            width="20"
            height="20"
            hide-placeholder
            contain
          />
        </template>
        <template v-slot:content="item">
          <v-list-item-title class="caption" v-html="item.title"/>
        </template>
      </list>

    </v-card>
  </div>
</template>

<script>
import ApiField from '@/components/form/ApiField';
import DImage from '@/components/Image';
import { convertToUnit, deepEqual } from 'vuetify/lib/util/helpers';

export default {
  components: { ApiField, DImage },
  props: {
    value: null,
    selected: {
      type: Array,
      default: () => []
    },
    label: String,
    rules: Array,
    hideDetails: Boolean,
    returnObject: Boolean,
    minHeight: {
      type: [ Number, String ],
      default: 120
    },
    maxHeight: {
      type: [ Number, String ],
      default: 300
    }
  },
  data: () => ({
    model: {},
    internalSelection: []
  }),
  watch: {
    selected( value ) {
      this.internalSelection = this.computeSelected( value );
    },
    filterItems() {
      this.internalSelection = this.computeSelected( this.selected );
    },
    internalSelection( value, old ) {
      if ( deepEqual( value, old )) return;
      this.$emit( 'selected',
        value
          .filter( index => this.filterItemsList[index] )
          .map( index => this.filterItemsList[index].value )
      );
    }
  },
  computed: {
    classes() {
      return {
        'category-field': true,
        'category-field--hide-details': this.hideDetails
      }
    },
    computedMaxHeight() {
      return convertToUnit( Number( this.maxHeight ) - 50 );
    },
    filterItems() {
      return (( this.model && this.model.filters ) || [] ).map( filter => {
        return [
          { header: filter.name },
          ...( filter.filterItems || [] ).map( f => ({
            action: {
              icon: {
                name: 'mdi-checkbox-marked-circle',
                color: 'success',
                small: true
              }
            },
            avatar: {
              image: f.picture && f.picture.url,
              size: 20,
              tile: true
            },
            text: f.name,
            value: f.id
          }))
        ];
      }).flat();
    },
    filterItemsList() {
      return this.filterItems.filter( f => !f.header );
    }
  },
  methods: {
    computeSelected( value ) {
      return ( value || [] )
        .map( id => this.filterItemsList.findIndex( f => f.value === id ))
        .filter( i => i !== -1 )
    }
  },
  mounted() {
    this.model = this.$refs.input.$refs.input.selectedItems[0];
    this.$watch( '$refs.input.$refs.input.selectedItems', arr => this.model = arr[0] );
  }
}
</script>

<style>
.category-field .v-card {
  position: relative;
  border-color: rgba(0, 0, 0, 0.38) !important;
  position: relative;
  margin-bottom: 18px;
  padding-top: 50px;
}
.category-field .autocomplete-field {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
}
.category-field .v-list {
  padding: 0 8px 8px 8px;
  overflow: auto;
  max-height: 100%;
}
.category-field .v-select {
  position: static;
}
.category-field .v-text-field__details {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
}
.category-field.category-field--hide-details {
  margin-bottom: 0;
}
.category-field .v-item--active {
  color: var(--v-success-base);
}
.category-field .v-item--active::before {
  opacity: .05;
}
.category-field .v-list-item:not(.v-item--active) .v-list-item__action {
  display: none;
}
</style>
