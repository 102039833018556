<template>
  <api-field
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t('inputs.area.label')"
    :compute="computeAreas"
    :filters="computedFilters"
    :item-text="computeValue"
    api="area"
    item-search="name"
    clearable
  >
    <template v-slot:selection="{ item }">
      {{ computeDisplay( item ) }}
    </template>
  </api-field>
</template>

<script>
import ApiField from '@/components/form/ApiField';
import { computeAreas, computeValue, computeDisplay } from '@/views/Areas/utils';

export default {
  components: { ApiField },
  props: {
    filters: Array
  },
  computed: {
    computedFilters() {
      return [{
        field: 'parent',
        stringValue: 'null'
      }].concat( this.filters || [] );
    }
  },
  methods: {
    computeAreas,
    computeValue,
    computeDisplay
  }
}
</script>
