<script>
import VLabel from 'vuetify/lib/components/VLabel';
import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
import sizeable from 'vuetify/lib/mixins/sizeable';
import { componentProps } from '@/utils';
import config from './defaults';
import '../input.scss';

const defaultProps = {
  ...VAutocomplete.options.props,
  ...sizeable.options.props
};

export default {
  name: 'AutocompleteField',
  inheritAttrs: false,
  mixins: [ VAutocomplete, sizeable ],
  props: componentProps( defaultProps, config ),
  data: () => ({
    inputHeight: 0,
  }),
  computed: {
    externalLabel() {
      if ( this.singleLine ) return this.label;
      return '';
    },
    labelPosition() {
      return {
        paddingLeft: ( this.rounded
          ? this.prependWidth + this.inputHeight / 2
          : this.prependWidth
        ) + 'px'
      };
    }
  },
  methods: {
    genExternalLabel() {
      return this.externalLabel && this.$createElement( 'label', {
        staticClass: 'caption',
        style: this.labelPosition
      },[ this.$slots.label || this.externalLabel ]);
    },
    genLabel() {
      if ( ! this.showLabel || this.externalLabel ) return null;
      const data = {
        props: {
          absolute: true,
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: !this.isSingle && (this.isFocused || !!this.validationState),
          for: this.computedId,
          left: this.labelPosition.left,
          light: this.light,
          right: this.labelPosition.right,
          value: this.labelValue
        }
      };
      return this.$createElement( VLabel, data, this.$slots.label || this.label );
    },
    setInputHeight() {
      if ( ! this.$refs.input ) return;
      this.inputHeight = this.$refs.input.clientHeight;
    }
  },
  updated() {
    this.$nextTick( this.setInputHeight );
  },
  mounted() {
    this.setInputHeight();
  },
  render(h) {
    return h( 'div', {
      staticClass: 'autocomplete-field sizeable-field',
      class: this.sizeableClasses
    }, [
      this.genExternalLabel(),
      VAutocomplete.options.render.call( this, h ),
      this.$slots.extend
    ]);
  }
}
</script>

<style lang="scss">

$input-sizes: (
  'x-small': 30px,
  'small': 42px,
  'default': 56px
);

.autocomplete-field {

  .v-input {
    > .v-input__control > .v-input__slot > .v-select__slot {
      padding: 2px 0;
    }
    > .v-input__control > .v-input__slot > .v-select__slot > .v-input__append-inner,
    > .v-input__control > .v-input__slot > .v-select__slot > .v-input__prepend-inner {
      align-self: auto;
      margin-top: 0;
      display: flex;
      align-items: center;
    }
  }

  .v-select.v-input--dense .v-chip {
    margin: 0 10px 0 -5px;
  }

  @each $name, $size in $input-sizes {
    &.v-size--#{$name} .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections {
      min-height: $size;
    }
    &.v-size--#{$name} .v-chip {
      height: $size * .70;
    }
  }
}
</style>
