<template>
  <autocomplete-field
    ref="input"
    v-bind="$props"
    v-on="$listeners"
    :value="value"
    :loading="loading"
    :items="filteredItems"
    :search-input.sync="internalSearch"
    :item-value="itemValue"
    :item-text="itemText"
    :multiple="multiple"
    :return-object="returnObject"
    :clearable="clearable"
    cache-items
  >

    <!---->

    <template
      v-for="name in computedSlots"
      v-slot:[name]
    >
      <slot :name="name"/>
    </template>

    <template
      v-if="$scopedSlots.item"
      v-slot:item="data"
    >
      <slot name="item" v-bind="data"/>
    </template>

    <template
      v-if="$scopedSlots.message"
      v-slot:item="data"
    >
      <slot name="message" v-bind="data"/>
    </template>

    <template
      v-if="$scopedSlots.counter"
      v-slot:item="data"
    >
      <slot name="counter" v-bind="data"/>
    </template>

    <template
      v-if="hideSelection || $scopedSlots.selection"
      v-slot:selection="data"
    >
      <slot name="selection" v-bind="data">
        <span/>
      </slot>
    </template>

    <!--<template
      v-for="name in computedSlots"
      v-slot:[name]="slotData"
    >
      <slot :name="name" v-bind="slotData"/>
    </template>

    -->

  </autocomplete-field>
</template>

<script>
import AutocompleteField from '../AutocompleteField';
import { createFilters } from '@/utils';

const autocompleteFieldProps = { ...AutocompleteField.props };
delete autocompleteFieldProps.items;
delete autocompleteFieldProps.loading;

export default {
  components: { AutocompleteField },
  props: {
    ...autocompleteFieldProps,
    value: null,
    api: String,
    method: {
      type: String,
      default: 'page'
    },
    search: String,
    returnObject: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    hideSelection: Boolean,
    filters: Array,
    exclude: Array,
    compute: Function,
    itemSearch: String,
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: [ String, Array, Function ],
      default: 'name'
    }
  },
  data() {
    return {
      loading: false,
      internalSearch: this.search,
      items: [],
      slots: [
        'append',
        'append-item',
        'append-outer',
        'prepend',
        'prepend-inner',
        'prepend-item',
        'progress',
        'label',
        'no-data',
        'extend'
      ],
    }
  },
  computed: {
    computedSlots() {
      return this.slots.filter( k => this.$slots[k] );
    },
    computedItems() {
      if ( this.compute ) return this.compute( this.items );
      return this.items;
    },
    computedFilters() {
      return this.searchFilters.concat( this.filters || [] );
    },
    searchFilters() {
      if ( this.internalSearch ) {
        if ( this.itemSearch ) {
          return createFilters({ [this.itemSearch]: this.internalSearch });
        } else {
          console.warn(`The item-search is required or invalid.`);
        }
      }
      return [];
    },
    filteredItems() {
      if ( ! this.exclude || ! this.exclude.length ) return this.computedItems;
      return this.computedItems.filter( item => {
        return ! this.exclude.find( value => value === item[this.itemValue]);
      });
    }
  },
  watch: {
    value( value ) {
      this.add( value );
    },
    search( value ) {
      this.internalSearch = value;
    },
    internalSearch( value ) {
      clearInterval( this.interval );
      this.interval = setTimeout( this.onSearch, 300 );
      this.$emit( 'search', value );
    }
  },
  methods: {
    fetch( filters, page = 1, repeat = 1 ) {
      return this.$store.dispatch(`api/${this.api}/${this.method}`, {
        silence: true,
        save: false,
        filters,
        page
      }).then( res => {
        let items = res.data || [];
        if ( res.numPages > repeat ) {
          repeat = repeat || res.numPages - 1;
          return this.fetch( filters, page + 1, repeat + 1 ).then( data => {
            return items.concat( data );
          });
        }
        return items;
      });
    },
    find( filters ) {
      this.loading = true;
      return this.fetch( filters )
        .then( this.add )
        .catch( console.warn )
        .finally(() => this.loading = false );
    },
    add( value ) {

      if ( ! value ) return;
      const key = this.itemValue;

      if ( Array.isArray( value )) {

        value.forEach( this.add );

      } else if ( typeof value === 'object' ) {
        if ( ! this.items.some( i => i[key] === value[key] )) {
          this.items.push( value );
        }
      } else {
        if ( ! this.items.some( i => i[key] === value )) {
          this.find(
            createFilters({ [key]: value }).concat( this.filters || [] )
          );
        }
      }
    },
    isSearched( value ) {

      const key = this.itemSearch;
      const search = this.internalSearch;

      if ( ! value || ! search ) return false;
      if ( Array.isArray( value )) return value.some( this.isSearched )

      if ( this.returnObject ) {
        return search === value[key];
      } else {
        const item = this.items.find( i => i[this.itemValue] === value );
        return (item && item[key] === search) || search === value;
      }
    },
    onSearch() {
      if ( this.isSearched( this.value )) return;
      const filters = this.searchFilters.concat( this.filters || [] );
      return this.find( filters );
    }
  },
  beforeMount() {
    this.add( this.value );
  },
  mounted() {
    this.slots = this.slots.slice();
  }
}
</script>
