<template>
  <component
    v-bind="attrs"
    :is="component"
    :to="to"
  >
    <slot v-bind="{ text }">
      {{ text }}
    </slot>
  </component>
</template>

<script>
import colorable from 'vuetify/lib/mixins/colorable';

export default {
  mixins: [ colorable ],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    link: Boolean,
    color: {
      type: String,
      default: 'info'
    },
    baseUrl: {
      type: String,
      default: '/tag'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    component() {
      return this.link ? 'router-link' : 'span';
    },
    attrs() {
      return this.setTextColor( this.color );
    },
    to() {
      const value = this.item[this.itemValue];
      return this.link && value ? `${this.baseUrl}?id=${value}` : undefined;
    },
    text() {
      const value = this.item[this.itemText];
      return value ? '#' + value : '';
    }
  }
}
</script>
