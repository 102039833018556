<template>
  <div
    :class="classes"
    :style="styles"
  >
    <google-map
      ref="map"
      class="absolute layer"
      :center="center"
      :options="computedOptions"
      :zoom="computedZoom"
      v-on="computedListeners"
    >

      <google-marker
        v-if="editable && !hidePosition && !hidePositionShadow"
        :marker="center"
        :alignment="centerIconAlign"
      >
        <div class="google-maps-marker-center shadow">
          <slot name="marker:center">
            <v-icon :color="centerIconColor" x-large>
              {{ centerIcon }}
            </v-icon>
          </slot>
        </div>
      </google-marker>

      <google-marker
        v-else-if="!hidePosition && computedValue"
        :marker="computedValue"
        :alignment="centerIconAlign"
      >
        <div class="google-maps-marker-center">
          <slot name="marker:center">
            <v-icon :color="centerIconColor" x-large>
              {{ centerIcon }}
            </v-icon>
          </slot>
        </div>
      </google-marker>

      <google-marker
        v-for="( item, index ) in computedMarkers"
        v-on="item.on"
        :marker="item.marker"
        :key="index"
      >
        <slot :name="`marker:${item.marker.id || index }`">
          <v-icon v-bind="item.marker.attrs">
            mdi-map-marker
          </v-icon>
        </slot>
      </google-marker>

    </google-map>

    <div v-if="editable && !hidePosition" class="google-maps-position">
      <slot name="marker:center">
        <v-icon :color="centerIconColor" x-large>
          {{ centerIcon }}
        </v-icon>
      </slot>
    </div>

    <div class="google-maps-inner">
      <slot/>
    </div>

  </div>
</template>

<script>
import measurable from 'vuetify/lib/mixins/measurable';
import GoogleMap from 'vue2-google-maps/src/components/map';
import GoogleMarker from 'vue2-gmap-custom-marker';
import { deepEqual } from 'vuetify/lib/util/helpers';

const DEFAULT_CENTER = { // <- Barcelona
  lat: 41.38060209410865,
  lng: 2.1672973632812464
};

const compute = ( pos, keyA, keyB, invert ) => {
  pos = pos || {};
  if ( keyA && keyA !== keyB ) {
    if ( invert ) {
      pos = { ...pos, [keyA]: pos[keyB] };
      delete pos[keyB];
    } else {
      pos = { ...pos, [keyB]: pos[keyA] };
      delete pos[keyA];
    }
  }
  return pos;
};

export default {
  name: 'GoogleMaps',
  mixins: [ measurable ],
  components: { GoogleMap, GoogleMarker },
  props: {
    value: Object,
    options: Object,
    editable: Boolean,
    hidePosition: Boolean,
    hidePositionShadow: Boolean,
    autoCenter: Boolean,
    searchOnMount: {
      type: Boolean,
      default: true
    },
    search: [ String, Object ],
    language: String,
    latitudeKey: {
      type: String,
      default: 'lat'
    },
    longitudeKey: {
      type: String,
      default: 'lng'
    },
    zoom: {
      type: [ Number, String ],
      default: 10
    },
    markers: {
      type: Array,
      default: () => []
    },
    positions: {
      type: Array,
      default: () => []
    },
    centerIcon: {
      type: String,
      default: 'mdi-map-marker'
    },
    centerIconColor: {
      type: String,
      default: 'red'
    },
    centerIconAlign: {
      // top | right | left | bottom | center
      // topleft | lefttop, topright | righttop,
      // bottomleft | leftbottom, bottomright | rightbottom
      type: String,
      default: 'top'
    }
  },
  data: () => ({
    center: { ...DEFAULT_CENTER },
    service: null,
    searching: false,
    founded: []
  }),
  watch: {
    value: 'centrate',
    search: 'find',
    editable( value ) {
      value && this.centrate( this.center );
    },
    center( value, old ) {
      if ( deepEqual( value, old )) return;
      this.$emit( 'update:center', value );
      if ( this.editable ) {
        this.$emit( 'input', this.computePosition({ ...this.value, ...value }, true ));
      }
    },
    founded( value ) {
      this.$emit( 'founded', value );
    },
    computePositions() {
      this.autoCenter && this.centrate();
    }
  },
  computed: {
    classes() {
      const { centerIconAlign: align } = this;
      return {
        'google-maps': true,
        [`google-map--align-${align}`]: !!align
      }
    },
    styles() {
      return {
        minWidth: '300px',
        minHeight: '300px',
        ...this.measurableStyles
      };
    },
    computedValue() {
      const val = this.computePosition( this.value );
      return this.isValidPosition( val ) ? val : null;
    },
    computedOptions() {
      return {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        ...this.options
      };
    },
    computedZoom() {
      return Number( this.zoom );
    },
    computedMarkers() {
      return this.markers.filter( marker => {
        return marker && this.isValidPosition( marker.position );
      }).map(( marker, index ) => ({
        marker: {
          ...marker,
          attrs: { xLarge: true, color: 'primary', ...marker.attrs },
          on: undefined
        },
        on: this.createMarkerListeners( marker, index )
      }));
    },
    computedPositions() {
      return ( this.computedMarkers
        .map( i => i.marker.position )
        .concat( this.founded.map( i => i.position ))
        .concat( this.positions )
        .filter( this.isValidPosition )
      );
    },
    computedListeners() {

      const listeners = {
        ...this.$listeners,
        dragend: this.setCenterHandler('dragend'),
        zoom_changed: this.setCenterHandler('zoom_changed'),
        change: null,
        input: null
      };

      return Object.keys( listeners ).reduce(( obj, key ) => {
        const listener = this.createListener( listeners[key] );
        if ( listener ) obj[key] = listener;
        return obj;
      },{});
    }
  },
  methods: {
    getMap() {
      return new Promise( resolve => {
        if ( ! this.$refs.map ) {
          setTimeout(() => this.getMap().then( resolve ), 10 );
        } else {
          this.$refs.map.$mapPromise.then( resolve );
        }
      })
    },
    getService() {
      return this.getMap().then( map => {
        this.service = this.service || new window.google.maps.places.PlacesService( map );
        return this.service;
      });
    },
    getCenter() {
      return this.getMap().then( map => {
        const center = map.getCenter();
        return {
          lat: center.lat(),
          lng: center.lng()
        };
      });
    },
    getGeocoder() {
      return this.geocoder || ( this.geocoder = new window.google.maps.Geocoder());
    },
    getAddress( pos ) {
      return new Promise( resolve => {

        if ( ! this.isValidPosition( pos ))
          return resolve([]);

        const latLng = new window.google.maps.LatLng( pos.lat, pos.lng );
        const geocoder = this.getGeocoder();

        geocoder.geocode({ latLng }, ( results, status ) => {
          if ( status === window.google.maps.GeocoderStatus.OK ) {
            resolve( results );
          } else {
            resolve([]);
          }
        })
      });
    },
    centrate( pos ) {

      if ( ! this.isValidPosition( pos )) pos = this.computePosition( pos );
      if ( ! this.isValidPosition( pos )) pos = null;

      this.getMap().then( map => {
        if ( ! pos ) {

          const positions = this.computedPositions;

          if ( positions.length <= 1 )
            return positions[0] && this.centrate( positions[0] );

          const bounds = new window.google.maps.LatLngBounds();

          positions.forEach( pos => {
            bounds.extend( new window.google.maps.LatLng( pos.lat, pos.lng ));
          });

          map.fitBounds( bounds );
          this.getCenter( this.centrate );

        } else {
          this.center = {
            lat: pos.lat,
            lng: pos.lng
          };
        }
      });
    },
    createListener( handler ) {
      if ( typeof handler !== 'function' ) return;
      return ( ...args ) => {
        this.getMap().then( map => {
          handler.call( this, map, ...args );
        });
      }
    },
    createMarkerListener( handler, marker, index ) {
      if ( typeof handler !== 'function' ) return;
      return function( event ) {
        return handler( event, marker, index );
      }
    },
    createMarkerListeners( marker, index ) {
      return Object.keys( marker.on || {} ).reduce(( obj, key ) => {
        const listener = this.createMarkerListener( marker.on[key], marker, index );
        if ( listener ) obj[key] = listener;
        return obj;
      },{});
    },
    computePosition( pos, invert ) {
      pos = compute( pos, this.latitudeKey, 'lat', invert );
      pos = compute( pos, this.longitudeKey, 'lng', invert );
      return pos;
    },
    isValidPosition( pos ) {
      if ( ! pos ) return false;
      if ( pos.lat == null || pos.lng == null ) return false;
      if ( isNaN( +pos.lat ) || isNaN( +pos.lng )) return false;
      return true;
    },
    setCenterHandler( listener ) {
      return map => {
        this.getCenter().then( this.centrate );
        this.$listeners[listener] && this.$listeners[listener].call( this, map );
      }
    },
    find( query ) {
      return new Promise(( resolve, reject ) => {

        if ( this.searching ) return resolve([]);

        if ( ! query )
          return resolve( this.founded = [] );

        this.searching = true;

        this.getService().then( service => {

          let error;
          const statusList = window.google.maps.places.PlacesServiceStatus;

          const request = {
            language: this.language || undefined,
            //location: this.center,
            ...( typeof query === 'object' ? query : { query })
          };

          service.textSearch( request, ( response, status ) => {
            if ( status === statusList.OK ) {
              this.founded = response.map( data => ({
                title: data.formatted_address,
                position: data.geometry.location.toJSON(),
                data
              }));
            } else {
              this.founded = [];
              if ( status !== statusList.ZERO_RESULTS )
                error = { response, status };
            }

            if ( error ) {
              this.$emit( 'error', error );
              return reject( error );
            }

            resolve( this.founded );
            this.searching = false;
          })
        });
      });
    }
  },
  mounted() {
    this.centrate( this.value );
    this.searchOnMount && this.find( this.search );
  }
};
</script>

<style>
.google-maps {
  position: relative;
}
.google-maps .google-maps-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
}
.google-maps-marker-center.shadow {
  opacity: .5;
}
.google-maps-position {
  position: absolute;
  top: 50%;
  left: 50%;
}
.google-map--align-top .google-maps-position {
  transform: translate( -50%, -100% );
}
.google-map--align-left .google-maps-position {
  transform: translate( -100%, -50% );
}
.google-map--align-right .google-maps-position {
  transform: translate( 0, -50% );
}
.google-map--align-bottom .google-maps-position {
  transform: translate( -50%, 0 );
}
.google-map--align-center .google-maps-position {
  transform: translate( -50%, -50% );
}
.google-map--align-topleft .google-maps-position,
.google-map--align-lefttop .google-maps-position {
  transform: translate( -100%, -100% );
}
.google-map--align-topright .google-maps-position,
.google-map--align-righttop .google-maps-position {
  transform: translate( 0, -100% );
}
.google-map--align-bottomleft .google-maps-position,
.google-map--align-leftbottom .google-maps-position {
  transform: translate( -100%, 0 );
}
</style>
