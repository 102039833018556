<template>
  <div
    ref="container"
    :class="classes"
  >

    <label v-if="label" class="caption">
      {{ label }}
    </label>

    <v-card
      class="pa-2"
      :min-height="minHeight"
      :outlined="outlined"
      v-bind="$attrs"
    >

      <api-field
        label="TAGSS"
        v-model="internalValue"
        :search="internalSearch"
        :api="api"
        :item-text="itemText"
        :item-search="itemSearch"
        :item-value="itemValue"
        :rules="rules"
        :hide-details="hideDetails"
        @keypress.enter="onEnter"
        @search="internalSearch = $event"
        class="mb-1"
        return-object
        hide-selection
        hide-selected
        multiple
      >
        <template v-slot:no-data>
          <span>
            <btn
              v-if="internalSearch"
              class="mx-2"
              color="primary"
              :loading="adding"
              @click="add"
            >
              {{ $t('btn.add') }} "{{ internalSearch }}"
            </btn>
          </span>
        </template>
      </api-field>

      <v-chip
        class="ma-1"
        color="grey lighten-4"
        v-for="(tag,i) in internalValue"
        :key="i"
        small
      >

        <tag
          :item="tag"
          :item-value="itemValue"
          :item-text="itemText"
        />

        <btn
          class="ml-1 mr-n2"
          @click="remove(tag)"
          x-small icon
        >
          <v-icon x-small>
            $close
          </v-icon>
        </btn>
      </v-chip>

    </v-card>
  </div>
</template>

<script>
import ApiField from '@/components/form/ApiField';
import Tag from '@/components/values/Tag';
import { deepEqual } from 'vuetify/lib/util/helpers';

export default {
  components: { ApiField, Tag },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    minHeight: {
      type: [ Number, String ],
      default: 120
    },
    outlined: {
      type: Boolean,
      default: true
    },
    hideDetails: Boolean,
    label: String,
    api: String,
    search: String,
    rules: Array,
    itemSearch: {
      type: String,
      default: 'value'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'value'
    },
    sanitize: Function
  },
  data: () => ({
    internalValue: [],
    internalSearch: '',
    adding: false
  }),
  computed: {
    classes() {
      return {
        'tag-field': true,
        'tag-field--hide-details': this.hideDetails
      }
    }
  },
  watch: {
    value( value, old ) {
      if ( deepEqual( value, old )) return;
      this.internalValue = this.computeTags( value );
    },
    search( value ) {
      this.internalSearch = value;
    },
    internalValue( value ) {
      this.$emit( 'input', value );
    }
  },
  methods: {
    computeTags( tags ) {
      return ( tags || [] ).map( tag => {
        if ( ! tag || ! tag[ this.itemText ] ) return;
        if ( typeof tag === 'string' ) {
          tag = {
            [this.itemValue]: tag,
            [this.itemText]: tag
          };
        }
        return tag;
      }).filter( Boolean );
    },
    onEnter() {
      if ( ! this.internalSearch ) return;
      this.add();
    },
    remove( tag ) {
      const index = this.internalValue.indexOf( tag );
      if ( index !== -1 ) {
        this.internalValue.splice( index, 1 );
      }
    },
    add() {

      if ( this.adding ) return;
      this.adding = true;

      let model = {
        id: -1,
        status: 1,
        [this.itemText]: this.internalSearch
      };

      if ( this.sanitize )
        model = this.sanitize( model );

      return this.$store.dispatch(`api/${this.api}/set`, {
        ...model,
        silence: true
      }).then( response => {
        this.internalValue.push( response );
        this.internalSearch = '';
      }).finally(() => {
        this.$refs.container.focus();
        this.adding = false;
      });
    }
  },
  beforeMount() {
    this.internalValue = this.computeTags( this.value );
    this.internalSearch = this.search;
  }
}
</script>

<style>
.tag-field .v-card {
  border-color: rgba(0, 0, 0, 0.38) !important;
  position: relative;
  margin-bottom: 18px;
}
.tag-field .v-select {
  position: static;
}
.tag-field .v-text-field__details {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
}
.tag-field.tag-field--hide-details {
  margin-bottom: 0;
}
</style>
